<div class="container-fluid">
  <div class="row fixed-topheader">
    <div class="row sticky-top">
      <div class="d-flex justify-content-between dashboard-mainboady">
        <div class="dashboard-heading">
          <h3>{{'redeemPoints'|translate}}</h3>
        </div>
        <div class="dashboard-headingicons">
          <app-header></app-header>
        </div>
      </div>
      <hr class="redeem-dashboardhr-line" />

    </div>
  </div>
  <!-- page parent div -->
  <div class="row fixed-body">
    <!-- user validdation div -->
    <div class="container" *ngIf="!isShowRedeemDiv">
      <div class="row verification-div">
        <!-- password validation div -->
        <div class="col-md-5" *ngIf="isShowVerified === 'verified'">
          <div class="p-3 validation-div">
            <div class="row mb-2">
              <span class="fs-6">{{'validatePassword'|translate}}</span>
            </div>
            <div class="rd-enterpassowrd-div">
              <div class="input-group mb-3 txt-group">
                <input type="password" [type]="!show ? 'password' : 'text'" [placeholder]="'password'|translate"
                  class="form-control revalidation-password" id="txtPassword" autocomplete="off"
                  [(ngModel)]="passwordValue" (keyup.enter)="showRedeemPage()">
                <span class="cursor-pointer" (click)="openPassword()" *ngIf="!show"><i
                    class="fa-regular fa-eye-slash"></i></span>
                <span class="cursor-pointer" (click)="openPassword()" *ngIf="show"><i
                    class="fa-sharp fa-regular fa-eye"></i></span>
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-primary btn-sm" (click)="showRedeemPage()"
                  [disabled]="!passwordValue">{{'submit'|translate}}</button>
              </div>
            </div>
          </div>
        </div>

        <!-- email unverify div -->
        <div class="col-md-5" *ngIf="isShowVerified === 'notVerified'">
          <div class="rd-enterpassowrd-div validation-div mb-3">
            <span class="text-primary d-flex justify-content-center align-center py-2 px-2">
              <i class="fa-sharp fa-solid fa-triangle-exclamation mx-1"></i>
              {{'alert'|translate}}
            </span>
            <div class="mb-3 text-secondary p-3 fs-6">
              {{'unVerifyAlertMessage'|translate}}
            </div>
            <div class="mb-3 text-center">
              <button class="btn btn-primary btn-sm" (click)="verifyEmail()">{{'resendVerifyEMail'|translate}}</button>
            </div>
          </div>
        </div>

        <div class="col-md-5" *ngIf="isShowVerified === 'w9NotVerified'">
          <div class="p-3 validation-div">
            <div class="rd-enterpassowrd-div">
              <span class="text-primary d-flex justify-content-center align-center py-2 px-2">
                <i class="fa-sharp fa-solid fa-triangle-exclamation mx-1"></i>
                {{'attention'|translate}}
              </span>
              <div class="mb-3 text-secondary p-2 fs-8">
                Please fill out the W9 details and upload the form to proceed with your next redemption. It's mandatory
                for compliance. Once done, you can proceed with your redemption.
              </div>
              <div class="mb-3 text-center">
                <button class="btn btn-primary btn-sm w-25" (click)="redirectW9Tab()">Next</button>
              </div>
            </div>
          </div>
        </div>

        <!-- loader -->
        <div class="col-md-5" *ngIf="isShowLoader">
          <div class="redeemverify-loader">
            <div class="loader">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>

        <!-- user levels section  -->
        <section class="col-md-7 px-3 redemption-rule-card">
          <div class="row pt-1 user-level-tophed">
            <div class="level-info">
              <div class="user-level-hed text-primary fw-bold">
                {{'userLevel'|translate}}
              </div>
            </div>
          </div>
          <div class="level-box-container">
            <div class="row">
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel == 'Level 1' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level1'|translate}}</div> 
                        <div class="px-1" placement="top" [ngbTooltip]="'levelRule1' | translate">
                           <span class="i-icon">
                              <span>i</span>
                           </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong>  1500 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel == 'Level 2' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                       <div class="fw-bold">{{'level2'|translate}}</div> 
                       <div class="px-1" [ngbTooltip]="'levelRule2' | translate">
                        <span class="i-icon">
                          <span>i</span>
                       </span>
                       </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 1000 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
  
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel== 'Level 3' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level3'|translate}}</div> 
                        <div class="px-1" placement="top" [ngbTooltip]="'levelRule3' | translate">
                          <span class="i-icon">
                            <span>i</span>
                         </span>
                        </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 500 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-2">
                <div
                  [ngClass]="userLevel== 'Level 4' ? 'card rd-level-card mb-0 currentUserLevel':'card mb-0 rd-level-card'">
                  <div class="d-flex">
                    <div>
                      <!-- <img src="../assets/icons/user-level-icon.png" alt=""> -->
                    </div>
                    <div class="fs-7 p-2">
                      <div class="d-flex justify-content-between">
                        <div class="fw-bold">{{'level4'|translate}}</div> 
                        <div class="px-1" [ngbTooltip]="'levelRule4' | translate">
                          <span class="i-icon">
                            <span>i</span>
                         </span>
                       </div>
                      </div>
                      <!-- <div class="text-dark mt-2">{{'redeemCondition1'|translate}}</div> -->
                      <div class="text-dark mt-2"> {{'redemptionEligibility'|translate}} :<strong> 100 {{'points'| translate | lowercase}} </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
      <div class="row mt-4 rd-level-parent w-100">
        <div class="row mt-4">
          <div class="level-info">
            <div class="user-level-hed text-primary fw-bold">
              {{'guidelines'|translate}}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="row mt-1 fs-8 px-2">
            <ol class="rd-list-group">
              <li>{{'guidelines1'|translate}}</li>
              <li>{{'guidelines2'|translate}}</li>
              <li>{{'guidelines3'|translate}}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <!--main Redemption div -->
    <div class="row redeem-point" *ngIf="isShowRedeemDiv">
      <div>
        <div class="row">
          <div class="col-md-12 mb-2">
            <div class="row">
              <!-- <h3 class="redeem-header">{{'points'|translate}}</h3> -->
              <div class="row">
                <div class="col-md-4">
                  <div class="card mb-0 h-100">
                    <small>{{'total'|translate}} {{'points'|translate}}</small>
                    <div class="text-primary fw-bold py-1 fs-4">{{totalPoint}}</div>
                    <div class="d-flex justify-content-between fs-9 w-100 mb-2">
                      <div>
                        <span>Earned Points</span>
                        <span class="fw-bold mx-2 text-primary">{{userTotalCompletePoints}}</span>
                      </div>
                      <div>
                        <span>Uni Points</span>
                        <span class="fw-bold mx-2 text-primary">{{userTotalTerminatePoints}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card  mb-0 h-100">
                    <small>{{'redeemed'|translate}} {{'points'|translate}}</small>
                    <div class="text-primary fw-bold py-1 fs-4">{{redeemPoint}}</div>
                    <div class="d-flex justify-content-between fs-9 w-100 mb-2">
                      <div>
                        <span>Earned Points</span>
                        <span class="fw-bold mx-2 text-primary">{{redeemedCompletePoints}}</span>
                      </div>
                      <div>
                        <span>Uni Points</span>
                        <span class="fw-bold mx-2 text-primary">{{redeemedTerminatePoints}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card mb-0 h-100">
                    <small>{{'balancePoints'|translate}}</small>
                    <div class="text-primary fw-bold py-1 fs-5">{{availablePoints}}</div>
                    <!-- <div
                      [ngClass]="minRedeemPoint > availablePoints ?'text-primary fw-bold fs-5':'text-primary fw-bold py-1 fs-4'"
                      class="text-primary fw-bold fs-5">{{availablePoints}}</div>
                    <div class="redeem-note d-flex align-center" *ngIf="minRedeemPoint > availablePoints">
                      <strong class="text-primary fw-bold">{{minRedeemPoint - availablePoints}}</strong>
                      <div class="px-1">{{'pointRemaining'|translate}}</div>
                    </div> -->

                    <div class="d-flex justify-content-between fs-9 w-100">
                      <div>
                        <span>Earned Points</span>
                        <span class="fw-bold mx-2 text-primary">{{userAvailableCompletePoints}}</span>
                      </div>
                      <div>
                        <span>Uni Points</span>
                        <span class="fw-bold mx-2 text-primary">{{userAvailableTerminatePoints}}</span>
                        <span data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                          <span class="i-icon p-0 points-info-i">
                            <span>i</span>
                        </span>

                          <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog points-info-modal modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h1 class="modal-title fs-5" id="staticBackdropLabel">Points System</h1>
                                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body fs-7">
                                  <section>
                                    <h6>Your total points are made up of two types: Unipoints and Complete Points. Here's how they work:</h6>
                                    <ol>
                                      <li>
                                        Unipoints ({{terminatePointsPercent}}% of total points)
                                        <ul>
                                          <li><strong>What are Unipoints?</strong></li>
                                          <p>Unipoints are bonus points that you receive without any effort or time taken on your part. They're our way of thanking you for being a part of our community.</p>
                                        </ul>
                                      </li>
                                      <li>
                                        Earned Points ({{completePointsPercent}}% of total points)
                                        <ul>
                                          <li><strong>What are Earned Points?</strong></li>
                                          <p class="mb-0">Earned Points are earned by completing surveys. The more surveys you complete, the more points you earn.</p>
                                          <li><strong>How do you earn Earned Points?</strong></li>
                                          <p>Simply participate in and complete surveys available on our platform.</p>
                                        </ul>
                                      </li>
                                    </ol>
                                  </section>
                                  <section>
                                    <h6>Example Scenario</h6>
                                     <p>Imagine you have completed several surveys and accumulated the following points:</p>
                                      <p class="mb-0">Total Points : 1500</p>
                                     <ol>
                                      <li><strong>UniPoints</strong> 450 (30% of 1500)</li>
                                      <li><strong>Earned Points</strong> 1050 (70% of 1500)</li>
                                    </ol> 
                                  </section>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="modal">Close</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-7 mb-2"> -->
          <!-- <h3 class="redeem-header">{{'amount'|translate}}</h3>
            <div class="d-flex">
              <div>
                <div class="r-total">
                  <div class="border px-3 bg-rigncolor text-dark rounded">USD</div>
                </div>
                <div class="r-total">
                  <div class="border px-3 bg-rigncolor text-dark rounded">{{currencyCode}}</div>
                </div>
              </div>
              <div class="points-summary text-left">
                <div class="r-point"> {{conversionAmount}}</div>
                <div class="r-point"> {{availableAmount}}</div>
              </div>
            </div>
            <div class="text-primary fs-7 mt-1 mx-1">
              <span>{{'1USD'|translate}}</span>
              <span class="mx-2">=</span>
              <span>{{'points_point'|translate}}</span>
            </div> -->
          <!-- </div> -->
        </div>
        <div class="row">
          <div class="row personal-detailtab pb-0">
            <ul class="nav">
              <li class="nav-item m-0 mb-3" (click)="redemptionOptions()"
                [ngClass]="!Active? 'personaldetails':'user-profile'">
                <a>{{'redemptionOptions'|translate}}</a>
              </li>
              <li class="nav-item" (click)="redemptionDetails()" [ngClass]="!Active? 'user-profile':'personaldetails'">
                <a>{{'redemptionDetails'|translate}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- start redemption option -->
      <div class="row redeem-cardgroup" [ngClass]="!isShowError ? '':'error-redeemDailog'" id="redemptionOption"
        *ngIf="!Active">
        <div [class.disabled-redeem-input]="!activeCard" class="col-md-3">
          <!-- selected reward  -->
          <div [attr.aria-disabled]="!activeCard ? 'false' : 'true'">
            <form [formGroup]="redeemRequestForm" (ngSubmit)="onRedeemRequest()">
              <!-- <h3 class="redeems-header">{{'selectedReward'|translate}}</h3> -->
              <div class="card  redeem-card cursor-unset selected-card">
                <img src="../../../../../assets/image/tango.png" class="card-image" alt="">
                <div>
                  <div class="card notselected-card mb-2">
                    <div class="card-center text-center text-light" *ngIf="!activeCard">{{'noRewardSelected'|translate}}
                    </div>
                    <div class="card-center text-center text-light" *ngIf="activeCard">{{redemptionCardTitle}}</div>
                  </div>
                </div>
                <!-- <div class="card-footer selected-card-footer px-0 pt-2">{{redemptionCardTitle}}</div> -->
                <div class="text-light fs-6 py-1">Redeemable Amount</div>
                <div class="fs-6">
                  <span class="text-light fw-bold">{{getCurrencySymbol() + finalExchangeAmount}} ({{'USD ' + finalRedeemBalancePoints/100}})</span>
                  <span class="i-icon p-0 points-info-i mx-1 fs-7" [ngbTooltip]="pointsSummary">
                    <span>i</span>
                </span>

                <ng-template #pointsSummary>
                 <span>Earned Points({{ userCompletePointsWithRedemption}})</span> +
                 <span> Uni Points({{ userTerminatePoints }})</span>
                </ng-template>
                </div>
                <!-- <div class="d-flex justify-content-between text-light w-100 fs-6">
                  <div class="USD">
                    <h6 class="text-center fs-6">{{userAvailableCompletePoints}} (${{userAvailableCompletePoints/ 100}})</h6>
                    <div class="r-total">
                      <div class="border px-3 bg-rigncolor text-light rounded">Earned Points</div>
                    </div>
                  </div>
                  <div class="vr"></div>
                  <div class="other-currency">
                    <h6 class="text-center fs-6">{{userTerminatePoints}} (${{userTerminatePoints/ 100}})</h6>
                    <div class="r-total">
                      <div class="border px-3 bg-rigncolor text-light rounded">Uni <br> points</div>
                    </div>
                  </div>           
                </div> -->
                <small class="redeem-calc-text">
                  <div class="text-light mt-1 mx-1">
                    <span>{{'1USD'|translate}}</span>
                    <span class="mx-2">=</span>
                    <span>{{'points_point'|translate}}</span>
                  </div>
                </small>
                <div *ngIf="Variable_Value">
                  <div class="card-footer selected-card-footer px-0 pt-2">
                    <!-- <div class="redeem-note">{{'redeemNote' | translate}} <strong> {{currencyCode}} </strong>
                      <strong>{{maxRedeemValue}} (${{maxRedeemPointValue}}) 
                        <span class="i-icon p-0 points-info-i mx-1" [ngbTooltip]="tooltipContent">
                          <span>i</span>
                      </span>
                      </strong>

                      <ng-template #tooltipContent>
                        <div>
                          <p class="mb-0">$15 is a combination of Earn points (70%) and Uni points (30%).</p>
                           <div class="text-start">
                            <p class="mb-0"><strong>Example Scenario</strong></p>
                            <p class="mb-0">Imagine you have completed several surveys and accumulated the following points:</p>
                           </div>
                          <ul class="text-start">
                            <li>Total Points: - 1500($15)</li>
                            <li>Earned Points: - 1050($10.5) (70% of 1500)</li>
                            <li>Unipoints: - 450($4.5) (30% of 1500)</li>
                          </ul>
                        </div>
                      </ng-template>
                    </div> -->
                    <div class="redeem-note">
                      <div *ngIf="maxRedeemPointValue < userFinalCompletePoints + userTerminatePoints">{{'redeemNote' | translate}} <strong>{{maxRedeemPointValue}}</strong></div>
                      <div style="font-size: 10px;padding-top: 3px;" *ngIf="getRedeemInfoText()">
                        <span class="fw-bold">Note: {{getRedeemInfoText()}}</span>
                      </div>
                     </div>
                    <input placeholder="Enter Amount" formControlName="getValue" id="txtAmount" type="text"
                      class="form-control p-1 mt-2" (keypress)="validationInput($event)" (paste)="(false)">
                  </div>
                </div>

                <div class="w-75" *ngIf="fixed_Value">
                  <select class="form-select form-select-md cursor-pointer" formControlName="getValue"
                    [(ngModel)]="selectedValue">
                    <option [ngValue]="item" *ngFor="let item of redemptionItem">
                      {{cardCurencySymbol()}}{{item.faceValue}}
                    </option>
                  </select>
                </div>

                <div *ngIf="Both_Value">
                  <div class="card-footer selected-card-footer px-0 pt-1">
                    <div class="redeem-note">{{'redeemNote' | translate}} <strong> {{currencyCode}} </strong>
                      <strong>{{maxRedeemValue}}</strong>
                    </div>
                    <input [placeholder]="'amount'|translate" formControlName="getValue" id="txtAmount" type="text"
                      #inputField class="form-control p-1 mt-2" (keypress)="validationInput($event)" (paste)="(false)">
                  </div>
                  <div class="text-light text-center mb-1">OR</div>
                  <select class="form-select form-select-md cursor-pointer" formControlName="getValue"
                    (change)="onDropdownChange()" [(ngModel)]="selectedValue">
                    <option [ngValue]="item" *ngFor="let item of redemptionItem">
                      {{cardCurencySymbol()}}{{item.faceValue}}
                    </option>
                  </select>
                </div>
              </div>
              <small class="row px-3 pb-2">
                <span class="p-0"> {{countryBaseMessage}} </span>
              </small>
              <div class="row mx-0  mb-2 align-center redeemBtn-info"
                [ngbTooltip]="availablePoints < minRedeemPoint || minCompleteLimit > userAvailableCompletePoints ? getRedeemInfoText() : ''">
                <button class="btn btn-primary" type="submit"
                  [disabled]="redeemRequestForm.invalid || availablePoints < minRedeemPoint || minCompleteLimit > userAvailableCompletePoints || finalRedeemBalancePoints < minRedeemPoint">{{'redeem'|translate}}
                </button>
                <!-- <button type="button" class="btn btn-outline-secondary mt-2" (click)="removeMessage()">Cancel</button> -->
              </div>
            </form>
          </div>
        </div>

        <!-- catalog -->
        <div class="col-md-9">
          <div class="row">
            <!-- non selected cards  -->
            <div class="card-container">
              <ng-container *ngFor="let item of redemptionOption;">
                <div [ngbTooltip]="!activeCard ? ('selectYourCard' | translate) : ''">
                  <div class="card redeem-card" (click)="selectReward(item)">
                    <img src="{{item.imageUrls['200w-326ppi']}}" class="card-image" alt="">
                    <div class="card-footer p-0 pt-2 redeem-note">{{item.brandName}}</div>
                  </div>
                </div>
              </ng-container>
            </div>
            <section [ngClass]="activeCard ? 'redeem-options questions':'border-0'">
              <div class="fs-6 mb-2 text-secondary" *ngIf="isShowCatelog">
                {{'viewRewardWeb'|translate}} {{tangoBrandCountries}} {{'checkGiftCard'|translate}}
              </div>
              <!-- show catelog -->
              <div [ngClass]="isShowCatelog?'row show-catelog':''">
                <div class="card-container tango-catelog" *ngIf="rewardCatelog">
                  <div *ngFor="let item of rewardCatelog">
                    <div class="card redeem-card p-1 mb-2">
                      <img src="{{item.imageUrls['200w-326ppi']}}" class="card-image" alt="">
                      <div class="card-footer show-catelog-footer p-0 pt-2">{{item.brandName}}</div>
                    </div>
                  </div>
                </div>
                <div class="text-center" *ngIf="isShowCatelogLoader">
                  <div class="redeemverify-loader">
                    <div class="loader">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div class="text-center text-secondary" *ngIf="isShowCatelog">
                  {{emptyCatelogMessage}}
                </div>
              </div>
            </section>
          </div>
          <div class="d-flex justify-content-center" *ngIf="isRedemptionLoader">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="text-center text-secondary" *ngIf="isEmptyRedemption">
            {{emptyRedemptionCard}}
          </div>
        </div>
        <hr>
        <div class="row">
          <strong class="fs-6 mt-3 mb-2 text-secondary">{{'rewardDisclaimer'|translate}}</strong>
          <span class="fs-6 text-secondary">{{'tangoCardRule1'|translate}}<a
              href="https://www.rewardsgenius.com/reward-link-terms-of-service">https://www.rewardsgenius.com/reward-link-terms-of-service.</a>
            {{'tangoCardRule2'|translate}}</span>
        </div>
      </div>

      <div [ngClass]="isShowError ?'div-error-redeemDailog':''" *ngIf="isShowError">
        <dialog open>{{'somethingWentWrong' | translate}}</dialog>
      </div>
      <!-- end redemption option -->
      <div class="row" id="redemptionDetail" *ngIf="Active">
        <section>
          <div class="row">
            <div class="dashboardbody">
              <h3>{{'redemptionDetails'|translate}}</h3>
            </div>
          </div>
          <div class="row dashboard-table table-responsive">
            <table class="table mb-4">
              <thead class="table-heading white-space-nowrap">
                <tr>
                  <!-- <th>{{'requestID'|translate}}</th> -->
                  <th>{{'redemptionType'|translate}}</th>
                  <th>{{'points'|translate}}</th>
                  <th>{{'amount'|translate}}</th>
                  <th>{{'currency'|translate}}</th>
                  <th>{{'status'|translate}}</th>
                  <th>{{('request'|translate) + ' ' + ('date'|translate )}} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of panelistRedeemHistory">
                  <!-- <td>{{item.id}}</td> -->
                  <td>{{item.rewardName}}</td>
                  <td>{{item.point}}</td>
                  <td>{{item.amount}}</td>
                  <td>{{item.currencyCode}}</td>
                  <td>{{item.status|translate}}</td>
                  <td>{{item.formattedDate}}</td>
                </tr>
              </tbody>
            </table>
            <div class="text-center text-secondary" *ngIf="showRedeemMessage">
              {{'emptyRedeemMessage'|translate}}
            </div>
            <hr />
            <div class="row dashboard-table-pagination">

            </div>
            <!-- <div class="row mt-3 mb-3">
                <span class="show-all-survey">
                  <a href="#">{{'referEarn'|translate}} </a>
                </span>
              </div> -->
          </div>

        </section>
      </div>
    </div>
  </div>
  <div class="page-footer"></div>

</div>